<template>
  <div>
    <f-page-header :title="learningPathTitle" :prevLinks="prevLinks" />

    <f-container topBottom>
      <v-col
        v-for="(item, index) in this.learningPath.learningPathsCourses"
        :key="index"
        align-self="center"
        justify="center"
        md="6"
        offset-md="3"
      >
        <v-row align="center" justify="center">
          <v-icon class="dots-color" x-large>mdi-dots-vertical</v-icon>
        </v-row>
        <v-row align="center" justify="center" no-gutters>
          <f-course-list-item
            :course="item"
            :progress="getUserCourseProgress(item)"
            :is-admin="isAdmin"
            :isLocked="index === 0 ? false : isLocked(progress[index - 1])"
            @view="view"
            @edit="edit"
            @remove="remove"
            @changeStatus="changeStatus"
          />
        </v-row>
      </v-col>
      <v-col align-self="center" justify="center" md="4" offset-md="4">
        <v-row align="center" justify="center">
          <v-icon class="dots-color" x-large>mdi-dots-vertical</v-icon>
        </v-row>
      </v-col>
      <!-- <v-col align-self="center" justify="center" md="4" offset-md="4">
        <v-row align="center" justify="center">
          <v-img
            src="@/assets/certificate-outline.svg"
            max-width="100px"
          />
        </v-row>
      </v-col> -->
    </f-container>
  </div>
</template>

<script>
import CourseListItem from '../../components/Courses/CourseListItem.vue';
import courseUtils from '../../helpers/course_utils';
import { UserType } from '../../helpers/user_utils';

export default {
  components: {
    'f-course-list-item': CourseListItem,
  },
  data: () => ({
    id: null,
    list: [],
    learningPath: {
      title: [],
      description: [],
    },
    flags: {
      loaded: false,
    },
    percent: null,
    progress: [],
    userProgress: {},
  }),
  computed: {
    title() {
      return this.$t('label.learningPath');
    },
    prevLinks() {
      var links = [
        {
          title: this.$t('labels.learningPaths'),
          route: {
            name: 'learning-paths-list',
          },
        },
      ];

      return links;
    },
    isAdmin() {
      return this.$store.state.auth.user.type == UserType.Admin;
    },
    learningPathTitle() {
      return this.learningPath.title[this.currentLanguage];
    },
    languages() {
      return this.learningPath && this.learningPath.langs
        ? this.learningPath.langs
        : [];
    },
    currentLanguage() {
      return this.languages.includes(this.$i18n.locale)
        ? this.$i18n.locale
        : this.languages[0];
    },
    learingPathDescription() {
      return this.learningPath.description[this.currentLanguage];
    },
    statusLabel() {
      return this.$i18n.t(`labels.status.${this.learningPath.status}`);
    },
    statusColor() {
      return this.learningPath.status === 'published' ? 'green' : 'gray';
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.get();
  },
  methods: {
    async get() {
      var result = await this.$store.dispatch('learningPaths/get', this.id);
      const learningPathsCourses = result.learningPathsCourses.filter(
        course => course != null
      );
      await Promise.all(
        learningPathsCourses.map(async course => {
          try {
            const id = course._id;
            const progress = await this.$store.dispatch(
              'progress/loadCourseProgress',
              id
            );
            this.userProgress[id] = progress;
          } catch (e) {}
        })
      );

      try {
        learningPathsCourses.forEach(course => {
          const progress = courseUtils.progressPercentage(
            course.modules,
            this.userProgress[course._id]
          );
          this.progress.push(progress);
          this.percent += Math.round(
            parseInt(progress) / learningPathsCourses.length
          );
        });
      } catch (e) {}

      result.learningPathsCourses = learningPathsCourses;

      this.learningPath = result;
      this.flags.loaded = true;
    },
    editLearningPath() {
      this.$router.push({
        name: 'learning-paths-edit',
        params: this.learningPath._id,
      });
    },
    getUserCourseProgress(course) {
      return 0;
      // return this.userProgress[course._id] ? this.userProgress[course._id] : [];
    },
    async changeStatus(id, status) {
      const result = await this.$store.dispatch('course/update', {
        _id: id,
        field: 'status',
        value: status,
      });
      if (result) {
        this.list = this.list.map(item => {
          if (item._id === id) {
            return { ...item, status };
          }
          return item;
        });
      }
    },
    view(id) {
      this.$router.push({
        name: 'course-view',
        params: { id },
      });
    },
    edit(id) {
      this.$router.push({
        name: 'course-edit',
        params: { id },
      });
    },
    async remove(id) {
      var response = await this.$store.dispatch('dialog/show', {
        title: this.$t('alerts.courses.remove.title'),
        content: this.$t('alerts.courses.remove.content'),
      });
      if (response) {
        await this.$store.dispatch('course/remove', id);
        this.get();
      }
    },
    back() {
      this.$router.push({
        name: 'learning-paths-list',
      });
    },
    isLocked(progress) {
      return progress != 100 ? true : false;
    },
  },
};
</script>

<style lang="scss">
.dots-color {
  color: #ffc107 !important;
  margin-top: 20px;
  margin-bottom: 25px;
}

.top-actions {
  margin: 10px 0 0 0;
  &__item {
    margin: 0 10px 0 0;
  }
}

.white--text {
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 300;
}
</style>
